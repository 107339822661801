import React, { useState, useEffect, useMemo } from 'react';
import { useBoilerplateStore } from '../store.js';
import './FilesContent.css'
import ContentEditable from 'react-contenteditable';

const ContextMenu = ({ x, y, onAddFolder, onAddText }) => {
    return (
      <div
        className="context-menu"
        style={{ top: y, left: x }}
      >
        <div className="context-menu-item" onClick={onAddFolder}>
          폴더 추가
        </div>
        <div className="context-menu-item" onClick={onAddText}>
          항목 추가
        </div>
      </div>
    );
};

function MyPage() {
    const [selectedInfo, setSelectedInfo] = useState("MyInfo");
    const [userInfo, setUserInfo] = useState({name:"나윤경", id:"choisken11", email: "choisken11@gmail.com"});
    const [userPlan, setUserPlan] = useState("Free");
    const {boilerplates, setBoilerplates, removeBoilerplate} = useBoilerplateStore();
    const [selectedFolder, setSelectedFolder] = useState([]);

    const selectFolder = (position, index) => {
        setSelectedFolder((prevSelected) => {
            const updatedFolders = [...prevSelected];

            if (updatedFolders[position] == index) {
                return prevSelected;
            }

            while (updatedFolders.length > position) {
                updatedFolders.pop();
            }

            updatedFolders[position] = index;

            return updatedFolders;
        });
    };
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event, position) => {
        event.preventDefault();
        setContextMenu({
            position,
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleAddFolder = () => {
        if (!contextMenu) return;

        console.log(contextMenu.position)

        if (contextMenu.position.length == 3) {
            alert("더이상 폴더를 추가할 수 없습니다.")
            return
        }
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new folder
        target.push({
          type: "folder",
          name: "새 폴더",
          contents: [],
        });
    
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleAddText = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new text item
        target.push({
          type: "text",
          name: "새 텍스트",
          text: "여기에 내용을 입력하세요.",
        });
    
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleRemoveItem = (e, length, index) => {
        e.stopPropagation(); 
        e.preventDefault(); 
        const confirmDelete = window.confirm("정말로 해당 항목을 삭제하시겠습니까?");
        if (confirmDelete) {
            removeBoilerplate([...selectedFolder.slice(0, length), index]);
        }
    }

    return (
        <>
        <div className="dashboard-header-wrap">
            <div className="dashboard-header-right">
                <div className="dashboard-user-name">{sessionStorage.getItem('SessionId')}</div>
                <div className="greetings">님 안녕하세요!</div>
                <img className="alarm-icon" src="/dashboard/alarm-icon.svg"/>
            </div>
        </div>
        <div className="dashboard-main-wrap">
            <div className="patent-list-title-wrap">
                <div className="patent-list-title">마이페이지</div>
            </div>
            <div className="info-selection-wrap">
                <div className={`select-info ${selectedInfo == "MyInfo" && "active"}`} onClick={()=>setSelectedInfo("MyInfo")}>내 정보</div>
                <div className={`select-info ${selectedInfo == "BoilerPlate" && "active"}`} onClick={()=>setSelectedInfo("BoilerPlate")}>보일러플레이트</div>
            </div>
            {selectedInfo == "MyInfo" &&
            <div className="my-info-wrap">
                <div className="my-profile-wrap">
                    <div className="my-profile-title">
                        내 프로필
                    </div>
                    <div className="my-profile-card">
                        <div className="my-profile-card-line">
                            <div className="my-profile-card-label">
                                이름
                            </div>
                            <div className="my-profile-card-info">
                                {userInfo.name}
                            </div>
                        </div>
                        <div className="my-profile-card-line">
                            <div className="my-profile-card-label">
                                ID
                            </div>
                            <div className="my-profile-card-info">
                                {userInfo.id}
                            </div>
                        </div>
                        <div className="my-profile-card-line">
                            <div className="my-profile-card-label">
                                E-mail
                            </div>
                            <div className="my-profile-card-info">
                                {userInfo.email}
                                <div className="change-btn">변경하기</div>
                            </div>
                        </div>
                        <div className="my-profile-card-line">
                            <div className="my-profile-card-label">
                                비밀번호
                            </div>
                            <div className="my-profile-card-info">
                                <div className="change-btn">변경하기</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plan-info-wrap">
                    <div className="plan-info-title">
                        구독 정보
                    </div>
                    <div className="plan-card-list">
                        <div className={`plan-card ${userPlan == "Free" && "active"}`}>
                            <div className="plan-card-title">
                                무료 체험
                            </div>
                            <div className="plan-card-subtitle">
                                아래 기능을 n일간 무료로 사용할 수 있어요.
                            </div>
                            <div className="plan-card-function-wrap">
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">기능 1</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">기능 2</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">기능 3</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">기능 4</div>
                                </div>
                            </div>
                            <div className="plan-card-action-btn">
                                {userPlan == "Free" ? "현재 플랜": "선택 불가"}
                            </div>
                        </div>
                        <div className={`plan-card ${userPlan == "Basic" && "active"}`}>
                            <div className="plan-card-title">
                                Basic 플랜
                            </div>
                            <div className="plan-card-subtitle">
                                Subtitle
                            </div>
                            <div className="plan-card-function-wrap">
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">기능 1</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">기능 2</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">기능 3</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <div className="plan-card-check-icon" />
                                    <div className="plan-card-function">기능 4</div>
                                </div>
                            </div>
                            <div className="plan-card-action-btn">
                                {userPlan == "Basic" ? "현재 플랜": "플랜 업그레이드"}
                            </div>
                        </div>
                        <div className={`plan-card ${userPlan == "Pro" && "active"}`}>
                            <div className="plan-card-title">
                                Pro 플랜
                            </div>
                            <div className="plan-card-subtitle">
                                Subtitle
                            </div>
                            <div className="plan-card-function-wrap">
                                <div className="plan-card-function-line">
                                    <img className="plan-card-check-icon" src={`/dashboard/${userPlan == "Pro" ? "plan-check-icon-active.svg": "plan-check-icon-default.svg"}`} />
                                    <div className="plan-card-function">기능 1</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <img className="plan-card-check-icon" src={`/dashboard/${userPlan == "Pro" ? "plan-check-icon-active.svg": "plan-check-icon-default.svg"}`} />
                                    <div className="plan-card-function">기능 2</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <img className="plan-card-check-icon" src={`/dashboard/${userPlan == "Pro" ? "plan-check-icon-active.svg": "plan-check-icon-default.svg"}`} />
                                    <div className="plan-card-function">기능 3</div>
                                </div>
                                <div className="plan-card-function-line">
                                    <img className="plan-card-check-icon" src={`/dashboard/${userPlan == "Pro" ? "plan-check-icon-active.svg": "plan-check-icon-default.svg"}`} />
                                    <div className="plan-card-function">기능 4</div>
                                </div>
                            </div>
                            <div className="plan-card-action-btn">
                                {userPlan == "Pro" ? "현재 플랜": "플랜 업그레이드"}
                            </div>
                        </div>
                    </div>
                    <div className="user-plan-info-wrap">
                        <div className="user-plan-info-line">
                            <div className="user-plan-info-label">구독 기간</div>
                            <div className="user-plan-info">2024. 10. 01 ~ 2024. 10. 31.</div>
                        </div>
                        <div className="user-plan-info-line">
                            <div className="user-plan-info-label">다음 청구일</div>
                            <div className="user-plan-info">2024. 11. 01</div>
                        </div>
                        <div className="user-plan-info-line">
                            <div className="user-plan-info-label">구독 해지</div>
                            <div className="user-plan-info">
                                <div className="unsubscribe-btn">해지 문의</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {selectedInfo == "BoilerPlate" &&
            <div className="boilerplate-wrap">
                <div className="boilerplate-list" onClick={() => {setContextMenu(null)}}>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 0))}>
                        {boilerplates.map((boilerplate, index) => {
                            if(boilerplate.type == "folder"){
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {handleRemoveItem(e, 0, index)}} onClick={()=> {selectFolder(0, index)}}><img className="boilerplate-icon" src="/dashboard/folder-icon.svg" /> 
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                    />
                                </div>
                            )
                            } else {
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {handleRemoveItem(e, 0, index)}}><img className="boilerplate-icon" src="/dashboard/text-icon.svg" /> 
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.text}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[index].text = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                    />
                                </div>
                                )
                            }
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 1))}>
                        {boilerplates[selectedFolder[0]]?.contents?.map((boilerplate, index) => {
                            if(boilerplate.type == "folder"){
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {handleRemoveItem(e, 1, index)}} onClick={()=> {selectFolder(1, index)}}><img className="boilerplate-icon" src="/dashboard/folder-icon.svg" />
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                    />
                                </div>
                            )
                            } else {
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {handleRemoveItem(e, 1, index)}}><img className="boilerplate-icon" src="/dashboard/text-icon.svg" />
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.text}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[index].text = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                    />
                                </div>
                                )
                            }
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 2))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents?.map((boilerplate, index) => {
                            if(boilerplate.type == "folder"){
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {handleRemoveItem(e, 2, index)}} onClick={()=> {selectFolder(2, index)}}><img className="boilerplate-icon" src="/dashboard/folder-icon.svg" />
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                    />
                                </div>
                            )
                            } else {
                                return (
                                <div key={index} className="boilerplate-item" onContextMenu={(e) => {handleRemoveItem(e, 2, index)}}><img className="boilerplate-icon" src="/dashboard/text-icon.svg" />
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.text}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[index].text = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                    />
                                </div>
                                )
                            }
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 3))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents[selectedFolder[2]]?.contents?.map((boilerplate, index) => {
                            return (
                            <div key={index} className="boilerplate-item" onContextMenu={(e) => {handleRemoveItem(e, 3, index)}}><img className="boilerplate-icon" src="/dashboard/text-icon.svg" />
                                <ContentEditable
                                    tagName="span"
                                    html={boilerplate.text}
                                    onChange={(event) => {
                                        const updatedText = event.target.value; // 수정된 텍스트
                                        const updatedBoilerplates = [...boilerplates];
                                        // 선택된 텍스트 업데이트
                                        updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[index].text = updatedText;
                                        setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                    }}
                                />
                            </div>
                            )
                        })}
                    </div>
                </div>
                {/* Render the context menu */}
                {contextMenu && (
                    <ContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    onAddFolder={handleAddFolder}
                    onAddText={handleAddText}
                    />
                )}
                <div className="boilerplate-folder-tree">
                    <div className="folder-tree-item">
                        {selectedFolder.map((folderIndex, depth) => {
                            // 현재 폴더를 탐색
                            const folder = selectedFolder
                                .slice(0, depth + 1)
                                .reduce((current, index) => {
                                    if (!current.contents) return current; // contents가 없는 경우 현재 폴더 그대로 반환
                                    return current.contents[index]; // 하위 폴더로 이동
                                }, { contents: boilerplates });

                            // 현재 폴더의 이름을 표시
                            return (
                                <React.Fragment key={depth}>
                                    {depth > 0 && <span className="folder-separator">{">"}</span>}
                                    <img className="boilerplate-icon" src="/dashboard/folder-icon.svg" />
                                    {folder.name || "폴더 없음"}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
            }
        </div>
        </>
    );
}

export default MyPage;