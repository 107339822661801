import React, { useState } from 'react';
import './ToolbarHome.css'

function ToolbarHome({ onCommand }) {

    return (
        <div className="toolbar">
            <div className="toolbar-paste">
                <div className="toolbar-paste-icon icon">
                    <img src="/toolbar/붙여넣기.svg" />
                </div>
                <div className="toolbar-paste-title">붙여넣기</div>
            </div>
            <div className="toolbar-edit-icons">
                <div className="toolbar-edit-icons-column">
                    <div className="toolbar-icon icon">
                        <img src="/toolbar/잘라내기.svg" />
                    </div>
                    <div className="toolbar-icon icon">
                        <img src="/toolbar/복사.svg" />
                    </div>
                    <div className="toolbar-icon icon">
                        <img src="/toolbar/서식복사.svg" />
                    </div>
                </div>
                <div className="toolbar-edit-icons-column">
                    <div className="toolbar-icon icon">
                        <img src="/toolbar/앞으로.svg" />
                    </div>
                    <div className="toolbar-icon icon">
                        <img src="/toolbar/뒤로.svg" />
                    </div>
                    <div className="toolbar-icon icon">
                        <img src="/toolbar/업로드.svg" />
                    </div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-font-settings">
                <div className="toolbar-font-upper-row">
                    <div className="toolbar-fontfamily">
                        <div className="toolbar-fontfamily-title">바탕</div>
                        <img src="/toolbar/arrow-down.svg" />
                    </div>
                    <div className="toolbar-fontsize">
                        <div className="toolbar-fontsize-title">12</div>
                        <img src="/toolbar/arrow-down.svg" />
                    </div>
                </div>
                <div className="toolbar-font-lower-row">
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('bold')}}>
                        <img src="/toolbar/bold.svg" alt="Bold" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('italic')}}>
                        <img src="/toolbar/italic.svg" alt="Italic" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('underline')}}>
                        <img src="/toolbar/underline.svg" alt="Underline" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('strikeThrough')}}>
                        <img src="/toolbar/cancelline.svg" alt="Strikethrough" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('superscript')}}>
                        <img src="/toolbar/위첨자.svg" alt="Superscript" />
                    </div>
                    <div className="toolbar-font-setting-icon icon" onClick={(e) => {e.preventDefault(); onCommand('subscript')}}>
                        <img src="/toolbar/아래첨자.svg" alt="Subscript" />
                    </div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-add-identifiers">
                <div className="add-identifier icon">
                    <div className="select-identifier">
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '')}}>[]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '도')}}>[도]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '표')}}>[표]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '청구항')}}>[청구항]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '수학식')}}>[수학식]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '화학식')}}>[화학식]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '반응식')}}>[반응식]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '실시예')}}>[실시예]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '특허문헌')}}>[특허문헌]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertIdentifier', '수탁기관')}}>[수탁기관]</div>
                    </div>
                    <div className="identifier-icon icon">
                        <img src="/toolbar/식별항목.svg" />
                    </div>
                    <div className="identifier-title">식별항목 추가</div>
                </div>
                <div className="add-identifier icon">
                    <div className="select-identifier">
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 설명')}}>[발명의 설명]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 명칭')}}>[발명의 명칭]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '기술분야')}}>[기술분야]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 배경이 되는 기술')}}>[발명의 배경이 되는 기술]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '해결하고자 하는 과제')}}>[해결하고자 하는 과제]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '과제의 해결 수단')}}>[과제의 해결 수단]</div>
                        <div className="identifier-wrap icon" onClick={(e) => {e.preventDefault(); onCommand('insertStandardIdentifier', '발명의 효과')}}>[발명의 효과]</div>
                    </div>
                    <div className="identifier-icon icon">
                        <img src="/toolbar/표준식별항목.svg" />
                    </div>
                    <div className="identifier-title">표준식별항목 추가</div>
                </div>
            </div>
            <div className="toolbar-divider"></div>
            <div className="toolbar-paragraph-settings">
                <div className="paragraph-settings-row">
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/왼쪽정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/가운데정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/우측정렬.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/양쪽정렬.svg" />
                    </div>
                </div>
                <div className="paragraph-settings-row">
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/단삭제.svg" />
                    </div>
                    <div className="paragraph-icon icon">
                        <img src="/toolbar/단추가.svg" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToolbarHome;