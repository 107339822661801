import './Tab.css';

function Tab({ labels, currentIndex, setIndex }) {
  return (
    <div className="tab-container">
      {labels.map((label, index) => {
        const isActive = index === currentIndex;

        return (
          <div
            key={label}
            className={`tab-label ${isActive ? 'active' : ''}`}
            onClick={() => setIndex(index)}
          >
            {label}
          </div>
        );
      })}
    </div>
  );
}

export default Tab;
