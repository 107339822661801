import './Dialog.css';

function Dialog({
  title,
  content,
  onCancel,
  onConfirm,
  cancelButtonText = '취소',
  confirmButtonText = '확인',
}) {
  return (
    <div className="dialog-container">
      <div className="dialog-title">{title}</div>
      <div className="dialog-content">{content}</div>
      <div className="dialog-button-container">
        <button className="dialog-confirm-button" onClick={onCancel}>
          {cancelButtonText}
        </button>
        <button className="dialog-cancel-button" onClick={onConfirm}>
          {confirmButtonText}
        </button>
      </div>
    </div>
  );
}

export default Dialog;
