import React, { useState } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';

function Header({onEdit}) {
    const navigate = useNavigate();

    const LogOut = () => {
        sessionStorage.setItem('SessionId', null);
        window.location.reload();
    };

    const GoHome = () => {
        navigate(`/`);
    }

    return (
        <div className="header-wrap">
            <>
                <div className="file-content-header-left">
                    <img src={process.env.PUBLIC_URL + '/aqua-logo-letter.svg'} alt="로고" onClick={GoHome} className="header-logo"/>
                </div>
                <div className="file-content-header-right">
                    <div className="user-name">{sessionStorage.getItem('SessionId')}</div>
                    <div className="logout-button" onClick={LogOut}>LOG OUT</div>
                </div>
            </>
        </div>
    );
}

export default Header;