import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePatentListStore } from './store.js';
import { CircularProgress, Box } from '@mui/material';
import { Button } from '@mui/material';
import { ModalContext } from './components/ModalProvider.js';
import { CREATE_FILE_KEY } from './constants/modal.js';
import Airtable from 'airtable';
import Dialog from './components/Dialog.js';
import './CreateNewFile.css';

function CreateNewFile() {
  const navigate = useNavigate();
  const { appendPatent } = usePatentListStore();

  const [patentID, setPatentID] = useState('');
  const [title, setTitle] = useState('');
  const [client, setClient] = useState('');
  const [receiptDate, setReceiptDate] = useState('');
  const [due, setDue] = useState('');
  const [note, setNote] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const { closeModal, openModal } = useContext(ModalContext);

  const validateCloseModal = () => {
    if (patentID || title || client || due) {
      return false;
    }

    return true;
  };

  const handleCloseModal = () => {
    if (validateCloseModal()) {
      closeModal(CREATE_FILE_KEY);
    } else {
      openModal(
        'KEY',
        <Dialog
          title="경고"
          content={`현재까지 작성한 내용이 저장되지 않습니다. \n 정말 나가시겠습니까?`}
          confirmButtonText="나가기"
          onCancel={() => closeModal('KEY')}
          onConfirm={() => closeModal(CREATE_FILE_KEY)}
        />
      );
    }
  };

  const base = new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  }).base('appZiodrIIUYXLYpC');

  const userID = sessionStorage.getItem('SessionId');

  const SaveAndGoHome = () => {
    if (!patentID || !title || !client || !due) {
      alert('도면을 제외한 모든 입력값들을 채워주세요.');
      return;
    }

    const newPatent = {
      id: patentID,
      title,
      client,
      due,
    };

    base('input data').create(
      [
        {
          fields: {
            사건번호: patentID,
            회원정보: userID,
            제목: title,
            클라이언트: client,
            마감기한: due,
            비고: note,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          alert('등록에 실패하였습니다.');
          return;
        }
        records.forEach(function (record) {
          console.log(record.getId());
        });

        setIsLoading(true); // 로딩 상태로 전환

        setTimeout(() => {
          appendPatent(newPatent);
          setIsLoading(false); // 로딩 상태 종료
          setIsDone(true);
        }, 1000); // 1초 대기 후 이동
      }
    );
  };

  return (
    <>
      <div className="">
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 1000,
              flexDirection: 'column',
            }}
          >
            <CircularProgress />
            <div className="wait">잠시만 기다려주세요.</div>
          </Box>
        )}
        {isDone && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 1000,
              flexDirection: 'column',
            }}
          >
            <div className="done-box">
              <div className="done">사건생성이 완료되었습니다.</div>
              <Button variant="contained" onClick={() => navigate(`/`)}>
                확인
              </Button>
            </div>
          </Box>
        )}
        {/* <Header /> */}
        <main className="create-file-container">
          <div className="create-file-title">새 사건 등록</div>
          <div className="content-column">
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">사건 번호</div>
                <div className="required-option-label">(필수)</div>
              </div>
              <input
                className="content-input"
                placeholder="ex. 003234"
                value={patentID}
                onChange={(e) => setPatentID(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">제목</div>
                <div className="required-option-label">(필수)</div>
              </div>
              <input
                className="content-input"
                placeholder="제목을 입력해주세요"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">클라이언트</div>
              </div>
              <input
                className="content-input"
                placeholder="클라이언트명을 입력해주세요"
                value={client}
                onChange={(e) => setClient(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">사건 접수일</div>
              </div>
              <input
                className="content-input"
                type="date"
                value={receiptDate}
                onChange={(e) => setReceiptDate(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">최종 송부 기한</div>
              </div>
              <input
                className="content-input"
                type="date"
                value={due}
                onChange={(e) => setDue(e.target.value)}
              />
            </div>
            <div className="content-wrap">
              <div className="content-title-wrap">
                <div className="content-title">비고</div>
              </div>
              <textarea
                className="textarea-note"
                placeholder="내용을 입력해주세요."
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
          <div className="btns-wrap">
            <button className="save-btn" onClick={SaveAndGoHome}>
              생성하기
            </button>
            <button className="back-btn" onClick={handleCloseModal}>
              취소
            </button>
          </div>
        </main>
      </div>
    </>
  );
}

export default CreateNewFile;
