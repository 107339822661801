import React, { useState } from 'react';
import FilesContent from './files-components/FilesContent'
import './FileSelection.css'


function FileSelection() {
    return (
            <div className="file-selection-body">
                <FilesContent/>
            </div>
    );
}

export default FileSelection;