import { create } from "zustand";
import { persist } from 'zustand/middleware';

const initialPatentList = [
  { id: "1", title: "반도체장비", client: "삼성전자", due: "2024-09-12", inventionContent:"asdasd.asdasdasd. asdasdd. asdaasd. \nasdasd.asdasd.\n\n\nasdasd.asdads" },
  { id: "2", title: "커피머신", client: "삼성전자", due: "2024-09-20" },
  { id: "3", title: "가열장치", client: "삼성전자", due: "2024-09-25" }
];

export const usePatentListStore = create(
  persist(
    (set, get) => ({
      patentList: initialPatentList,

      setPatentList: (patentList) => set({ patentList: patentList }),

      appendPatent: (patent) => set({ patentList: [...get().patentList, patent] }),

      removePatentById: (id) => set({
        patentList: get().patentList.filter(patent => patent.id !== id)
      }),

      updatePatentById: (id, updatedPatent) => set({
        patentList: get().patentList.map(patent =>
          patent.id === id ? { ...patent, ...updatedPatent } : patent
        )
      }),

      getContentById: (id) => {
        const patent = get().patentList.find(patent => patent.id === id);
        return patent ? patent.inventionContent : null;
      },

      setContentById: (id, newContent) => set({
        patentList: get().patentList.map(patent =>
          patent.id === id ? { ...patent, inventionContent: newContent } : patent
        )
      }),

      getMetadataById: (id) => {
        const patent = get().patentList.find(patent => patent.id === id);
        return patent ? patent.contentMetadata : null;
      },

      setMetadataById: (id, newMetadata) => set({
        patentList: get().patentList.map(patent =>
          patent.id === id ? { ...patent, contentMetadata: newMetadata } : patent
        )
      })
    }),
    {
      name: 'patent-list-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);

export const useIdentifierListStore = create(
  persist(
    (set, get) => ({
      identifiers: [],
      standardIdentifiers: [],
      claims: [],
      images: [],

      setIdentifiers: (identifiers) => set({ identifiers: identifiers }),
      setStandardIdentifiers: (standardIdentifiers) => set({ standardIdentifiers: standardIdentifiers }),
      setClaims: (claims) => set({ claims: claims }),
      setImages: (images) => set({ images: images }),
    }),
    {
      name: 'patent-list-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);

const fileTreeEample = [
      {
          "name": "업무 관련",
          "type": "folder",
          "contents": [
          {
              "type": "folder",
              "name": "보고서",
              "contents": [
              {
                  "type": "text",
                  "name": "주간 보고",
                  "text": "이번 주 주요 진행 상황은 다음과 같습니다..."
              },
              {
                  "type": "text",
                  "name": "월간 보고",
                  "text": "지난 한 달간의 주요 활동은 다음과 같습니다..."
              }
              ]
          },
          {
              "type": "folder",
              "name": "회의록",
              "contents": [
                {
                    "type": "folder",
                    "name": "회의주제1",
                    "contents": [
                    {
                        "type": "text",
                        "name": "팀 회의록1",
                        "text": "회의주제 1은 이거였음"
                    }
                    ]
              },
              {
                  "type": "text",
                  "name": "팀 회의록",
                  "text": "팀 회의 주요 논의 사항은 다음과 같습니다..."
              }
              ]
          }
          ]
      },
      {
          "name": "개인 용도",
          "type": "folder",
          "contents": [
          {
              "type": "text",
              "name": "자기소개",
              "text": "안녕하세요, 저는 ..."
          },
          {
              "type": "folder",
              "name": "메모",
              "contents": [
              {
                  "type": "text",
                  "name": "할 일",
                  "text": "1. 장보기\n2. 운동하기"
              }
              ]
          }
          ]
      }
]

export const useBoilerplateStore = create(
  persist(
    (set, get) => ({
      boilerplates: fileTreeEample,

      setBoilerplates: (boilerplates) => set({ boilerplates: boilerplates }),
      
      removeBoilerplate: (position) => {
        const boilerplates = [...get().boilerplates]; // 상태 복사
        let currentLevel = boilerplates;

        // position 배열을 순회하며 대상 항목 탐색
        for (let i = 0; i < position.length - 1; i++) {
          currentLevel = currentLevel[position[i]].contents;
        }

        // 마지막 요소 삭제
        currentLevel.splice(position[position.length - 1], 1);

        // 상태 업데이트
        set({ boilerplates });
      },
    }),
    {
      name: 'boilerplates-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);