import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePatentListStore, useIdentifierListStore } from './store.js';
import './ContentEditor3.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Airtable from 'airtable';
import ToolbarHome from './components/ToolbarHome.js'
import ToolbarInput from './components/ToolbarInput.js'
import ToolbarTools from './components/ToolbarTools.js'
import ToolbarView from './components/ToolbarView.js'
import ToolbarHelp from './components/ToolbarHelp.js'
import TextEditor from './components/TextEditor.js'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ContentEditor() {
    const navigate = useNavigate();
    const { getContentById, setContentById, getMetadataById, setMetadataById } = usePatentListStore();
    const { identifiers, standardIdentifiers, claims, images } = useIdentifierListStore();
    const [fileList, setFileList] = useState([]);
    const [recordIDList, setRecordIDList] = useState([]);
    const [patentRawContent, setPatentRawContent] = useState("");
    const [recordID, setRecordID] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedToolbarOption, setSelectedToolbarOption] = useState("Home")
    const [selectedNavOption, setSelectedNavOption] = useState("index")
    const [selectedAsideOption, setSelectedAsideOption] = useState("image")
    const [toolbarComponent, setToolbarComponent] = useState(<ToolbarHome />)
    const [showLeft, setShowLeft] = useState(true);
    const [showRight, setShowRight] = useState(true);
    
    const [command, setCommand] = useState(null);

    const applyCommand = (cmd, arg = null) => {
        setCommand({ cmd, arg });
    };


    useEffect(() => {
        if (selectedToolbarOption == "Home") {
            setToolbarComponent(<ToolbarHome  onCommand={applyCommand} />)
        } else if (selectedToolbarOption == "Input") {
            setToolbarComponent(<ToolbarInput />)
        } else if (selectedToolbarOption == "Tools") {
            setToolbarComponent(<ToolbarTools />)
        } else if (selectedToolbarOption == "View") {
            setToolbarComponent(<ToolbarView />)
        } else if (selectedToolbarOption == "Help") {
            setToolbarComponent(<ToolbarHelp />)
        } else {
            setToolbarComponent(<ToolbarHome />)
        }
    }, [selectedToolbarOption]);

    // Airtable 데이터 가져오기
    useEffect(() => {
        // const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base('appZiodrIIUYXLYpC');

        // base('output data').select({
        //     maxRecords: 10,
        //     view: "Grid view",
        // }).eachPage(function page(records, fetchNextPage) {
        //     const recordsList = records.map(record => record.fields);
        //     const recordsID = records.map(record => record.id);
        //     setRecordIDList(prevList => [...prevList, ...recordsID]);
        //     setFileList(prevList => [...prevList, ...recordsList]); 
        //     fetchNextPage();
        //     setIsLoading(false); // 로딩 상태로 전환
        // }, function done(err) {
        //     if (err) {
        //         console.error(err);
        //         return;
        //     }
        // });
    }, []);

    const query = useQuery();
    const patentID = query.get('id');

    const [inventionContent, setInventionContent] = useState(getContentById(patentID)||"");

    // const inventionContent = getContentById(patentID) || '';
    // const setInventionContent = (content) => {
    //     setContentById(patentID, content);
    // };

    // useEffect(() => {
    //     const updatedContent = inventionContent.split("\n");
    
    //     const modifiedContent = updatedContent.map((paragraph) => {
    //         if (paragraph.trim() !== "" && !paragraph.trim().endsWith(".")) {
    //             return paragraph + ".";
    //         }
    //         return paragraph;
    //     });
    
    //     setInventionContent(updatedContent.join('\n'));
    // }, [inventionContent]);

    useEffect(() => {
        setContentById(patentID, inventionContent);
        console.log(inventionContent)
    }, [inventionContent]);

    const [contentMetadata, setContentMetadata] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    const saveToServer = (inventionContent, contentMetadata) => {
        let integratedContent = ""
        const paragraphs = inventionContent.split("\n");
        paragraphs.map((paragraph, pIndex)=> {
            const sentences = paragraph.split(".");
            sentences.map((sentence,sIndex) => {
                if (sIndex == sentences.length -1) {
                    integratedContent = integratedContent + sentence
                } else {
                    integratedContent = integratedContent + sentence + "."
                }
                const sTag = contentMetadata.sTag.find((c)=> c.pIndex == pIndex && c.sIndex == sIndex)
                if(sTag) {
                    const sTagName = contentMetadata.sTagInfo.find((c)=> c.id == sTag.sTagInfoId).name
                    integratedContent = integratedContent + `**${sTagName}**`
                }
            })
            const pTag = contentMetadata.pTag.find((c)=> c.pIndex == pIndex)
            if(pTag) {
                const pTagName = contentMetadata.pTagInfo.find((c)=> c.id == pTag.pTagInfoId).name
                integratedContent = integratedContent + `\`\`${pTagName}\`\``
            }
            integratedContent = integratedContent + "\n"
        })

        const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base('appZiodrIIUYXLYpC');

        base('output data').update([
            {
                id: recordID,
                fields: {
                    사건번호: patentID,
                    output: integratedContent
                }
            }
        ], function(err, records) {
            if (err) {
                console.error(err);
                return;
            }
            records.forEach(function(record) {
                console.log(record.get('ID'));
            });
        });
    }

    useEffect(() => {
        if(isLoaded){
            saveToServer(inventionContent, contentMetadata)
        }
    }, [inventionContent, contentMetadata]);

    useEffect(() => {
        const index = fileList.findIndex((file) => file["사건번호"] === patentID);
        console.log(fileList)
        console.log(index)
        
        if (index !== -1) {  // 인덱스가 유효할 때만 실행
            setRecordID(recordIDList[index]);
            setPatentRawContent(fileList[index]?.output);
        } else {
            console.error('해당 사건번호를 찾을 수 없습니다.');
        }
    }, [fileList, patentID]); 

    const defaultColors = ['red', 'green', 'blue', 'cyan', 'magenta', 'orange', 'purple'];

    const assignColor = (index) => {
        return defaultColors[index % defaultColors.length];
    };


    // pTag와 sTagInfo 리스트를 업데이트하는 함수
    const updateContentAndTagsWithRawContent = (raw) => {
        const sTagInfoPattern = /\*\*(.*?)\*\*/g; // **sTagInfo** 추출
        const pTagPattern = /``([^`]*)``/g;   // ``pTagInfo`` 추출

        let contentWithoutTags = raw;

        let pTag = []
        let sTag = []


        // 새로운 태그 추가
        const newPTagInfo = [];
        const newSTagInfo = [{id: 1, name: "사용자 추가", color: "#FFDC2E"}];


        const paragraphs = raw.split("\n");
        paragraphs.map((paragraph, pIndex)=> {
            const sentences = paragraphs[pIndex].split(".");
            const pMatch = [...paragraph.matchAll(pTagPattern)].map(match => match[1])[0];
            if(pMatch) {
                const existingPTag = newPTagInfo.find((tag) => tag.name === pMatch);
                if (!existingPTag) {
                    const newId = newPTagInfo.length + 1;
                    newPTagInfo.push({
                        id: newId,
                        name: pMatch,
                        color: assignColor(newId)
                    });
                    pTag.push({pIndex:pIndex, pTagInfoId: newId})
                } else {
                    pTag.push({pIndex:pIndex, pTagInfoId: existingPTag.id})
                }
            }

            sentences.map((sentence,sIndex) => {
                const sMatch = [...sentence.matchAll(sTagInfoPattern)].map(match => match[1])[0];
                if(sMatch) {
                    const existingSTag = newSTagInfo.find((tag) => tag.name === sMatch);
                    if (!existingSTag) {
                        const newId = newSTagInfo.length + 1;
                        newSTagInfo.push({
                            id: newId,
                            name: sMatch,
                            color: assignColor(newId)
                        });
                        sTag.push({pIndex:pIndex, sIndex:sIndex-1, sTagInfoId: newId})
                    } else {
                        sTag.push({pIndex:pIndex, sIndex:sIndex-1, sTagInfoId: existingSTag.id})
                    }
                }
            })

        })

        // 태그 없는 본문 처리
        contentWithoutTags = contentWithoutTags.replace(pTagPattern, '').replace(sTagInfoPattern, '').trim();
        setInventionContent(contentWithoutTags);
        

        const updatedMetadata = {
            pTagInfo: newPTagInfo,
            sTagInfo: newSTagInfo,
            pTag: pTag,
            sTag: sTag,
        };

        setContentMetadata(updatedMetadata);
    };

    useEffect(() => {
        if(!patentRawContent) {
            return
        }
        updateContentAndTagsWithRawContent(patentRawContent);
        setIsLoaded(true);
    }, [patentRawContent]);

    const metadata = getMetadataById(patentID);

    useEffect(() => {
        if (!metadata) {
            return
        } else {
            setContentMetadata(metadata);
        }
        // console.log(contentMetadata)
    }, [getMetadataById, patentID]); 

    useEffect(() => {
        setMetadataById(patentID, contentMetadata)
    }, [contentMetadata]);

    const LogOut = () => {
        sessionStorage.setItem('SessionId', null);
        window.location.reload();
    };

    return (
        <div className="content-editor-wrap">
            {isLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1000,
                        flexDirection:'column',
                    }}
                >
                    <CircularProgress />
                    <div className="wait">Loading</div>
                </Box>
            )}
            {showLeft && 
            <nav className="nav">
                <div className="nav-header">
                    <div className="nav-header-left">
                        <div className={selectedNavOption == "index" ? "nav-header-item active": "nav-header-item"} onClick={() => setSelectedNavOption("index")}>
                            <div className="nav-header-item-icon">
                                <img src="/nav/index.svg" />
                            </div>
                            <div className="nav-header-item-title">
                                목차
                            </div>
                        </div>
                        <div className={selectedNavOption == "claim" ? "nav-header-item active": "nav-header-item"} onClick={() => setSelectedNavOption("claim")}>
                            <div className="nav-header-item-icon">
                                <img src="/nav/show-claim.svg" />
                            </div>
                            <div className="nav-header-item-title">
                                청구항별 보기
                            </div>
                        </div>
                    </div>
                    <div className="nav-header-right">
                        <div className="fold-icon" onClick={()=> setShowLeft(false)}>
                            <img src="/nav/fold.svg" />
                        </div>
                    </div>
                </div>
                <div className="nav-main">
                    {selectedNavOption == "index" && standardIdentifiers?.map((standardIdentifier, index) => {
                        return (
                            standardIdentifier && <div className="nav-main-item" key={index}>{standardIdentifier}</div>
                        )
                    })}
                    {selectedNavOption == "claim" && claims?.map((claim, index) => {
                        return (
                            claim && <div className="nav-main-item" key={index}>{claim}</div>
                        )
                    })}
                </div>
            </nav>
            }
            {showRight && 
            <aside className="aside">
                <div className="nav-header">
                    <div className="nav-header-left">
                        <div className={selectedAsideOption == "image" ? "nav-header-item active": "nav-header-item"} onClick={() => setSelectedAsideOption("image")}>
                            <div className="nav-header-item-icon">
                                <img src="/nav/image.svg" />
                            </div>
                            <div className="nav-header-item-title">
                                도면
                            </div>
                        </div>
                        <div className={selectedAsideOption == "boilerplate" ? "nav-header-item active": "nav-header-item"} onClick={() => setSelectedAsideOption("boilerplate")}>
                            <div className="nav-header-item-icon">
                                <img src="/nav/boilerplate.svg" />
                            </div>
                            <div className="nav-header-item-title">
                                보일러 플레이트
                            </div>
                        </div>
                    </div>
                    <div className="nav-header-right">
                        <div className="fold-icon" onClick={()=> setShowRight(false)}>
                            <img src="/nav/fold.svg" />
                        </div>
                    </div>
                </div>
                <div className="nav-main">
                    {selectedAsideOption == "image" && images?.map((image, index) => {
                        return (
                            image && <div className="nav-main-item" key={index}>{image}</div>
                        )
                    })}
                    {selectedAsideOption == "boilerplate" && identifiers?.map((identifier, index) => {
                        return (
                            identifier && <div className="nav-main-item" key={index}>{identifier}</div>
                        )
                    })}
                </div>
            </aside>
            }
            <div className="editor-fixed-headers">
                <div className="editor-header">
                    <div className="editor-header-left">
                        <div className='home-icon'>
                            <img src={process.env.PUBLIC_URL +'/home.svg'}/>
                        </div>
                    </div>
                    <div className="editor-header-center">
                        <div className='editor-header-title'>사건 제목</div>
                        <div className="more-icon">
                            <img src={process.env.PUBLIC_URL + "/more.svg"} />
                        </div>
                    </div>
                    <div className="editor-header-right">
                        <div className="user-name">{sessionStorage.getItem('SessionId')}</div>
                        <div className="editor-logout-button" onClick={LogOut}>로그아웃</div>
                    </div>
                </div>
                <div className="editor-toolbar-selector">
                    <div className={selectedToolbarOption == "Home" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("Home")}>
                        <div className="option-text">홈</div>
                    </div>
                    <div className={selectedToolbarOption == "Input" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("Input")}>
                        <div className="option-text">입력</div>
                    </div>
                    <div className={selectedToolbarOption == "Tools" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("Tools")}>
                        <div className="option-text">도구</div>
                    </div>
                    <div className={selectedToolbarOption == "View" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("View")}>
                        <div className="option-text">보기</div>
                    </div>
                    <div className={selectedToolbarOption == "Help" ? "toolbar-option active": "toolbar-option"} onClick={() => setSelectedToolbarOption("Help")}>
                        <div className="option-text">도움말</div>
                    </div>
                </div>
                {toolbarComponent}
            </div>
            <main className="main">
                <TextEditor command={command} />
            </main>
        </div>
    );
}

export default ContentEditor;