import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import FileSelection from './FileSelection';
import CreateNewFile from './CreateNewFile';
import EditFile from './EditFile';
import './App.css';
import ContentEditor from './ContentEditor3';
import { ModalProvider } from './components/ModalProvider';

function App() {
  return (
    <Router>
      <div className="App">
        <ModalProvider>
          <Routes>
            <Route path="/" element={<FileSelection />} />
            <Route path="/new" element={<CreateNewFile />} />
            <Route path="/edit" element={<EditFile />} />
            <Route path="/contentEditor" element={<ContentEditor />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ModalProvider>
      </div>
    </Router>
  );
}

export default App;
