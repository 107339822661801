import React, { useState, useEffect } from 'react';
import './Login.css';
import Airtable from 'airtable';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [IDInfoList, setIDInfoList] = useState([]);

    // Airtable 데이터 가져오기
    useEffect(() => {
        const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base('appZiodrIIUYXLYpC');

        base('회원정보').select({
            maxRecords: 10,
            view: "Grid view",
        }).eachPage(function page(records, fetchNextPage) {
            const recordsList = records.map(record => record.fields);
            setIDInfoList(prevList => [...prevList, ...recordsList]); // 리스트에 추가
            fetchNextPage();
        }, function done(err) {
            if (err) {
                console.error(err);
                return;
            }
        });
    }, []);

    // 로그인 처리 함수
    const handleLogin = async (e) => {
        e.preventDefault();
        const matchedUser = IDInfoList.find(user => user.ID === username && user.PASSWORD === password);

        if (matchedUser) {
            sessionStorage.setItem('SessionId', matchedUser.ID); // 일치하는 ID를 저장
            window.location.reload(); // 페이지 새로고침
        } else {
            alert('아이디 또는 비밀번호가 잘못되었습니다.');
        }
    };

    return (
        <div className="login-body">
            <div className="login-container">
                <div className="login-left">
                    <div className="login-left-title">변리사를 위한 새로운<br/>명세서 작성 솔루션</div>
                    <div className="login-left-subtitle-1">더 빠르게, 더 확실하게.</div>
                    <div className="login-left-subtitle-2">생성형 AI기술을 통해 초안을 작성하고,<br/>변리사가 검토하여 완성하는 특허명세서</div>
                    <div className="login-left-inquiry">도입문의</div>
                </div>
                <div className="login-right">
                    <img src="/drift-logo-black.svg"></img>
                    <form onSubmit={handleLogin}>
                        <div className="login-title">로그인</div>
                        <div className="login-line">
                            <label>
                                아이디
                            </label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                disabled={isLoading}
                                placeholder="아이디를 입력해주세요"
                            />
                        </div>
                        <div className="login-line">
                            <label>
                                패스워드
                            </label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                disabled={isLoading}
                                placeholder="패스워드를 입력해주세요"
                            />
                        </div>
                        <button type="submit" disabled={ !username || !password || isLoading}>
                            {isLoading ? 'Logging in...' : 'Log in'}
                        </button>
                        <div className='find-pw-wrap'>
                            <div className="find-pw-title">비밀번호를 잊으셨나요?</div>
                            <div className="find-pw-btn">문의하기</div>
                        </div>
                        <div className='signin-wrap'>
                            <div className="find-pw-title">계정이 없으신가요?</div>
                            <div className="find-pw-btn">도입 문의하기</div>
                        </div>
                    </form>
                    <div className="copyright">Copyright ⓒ 2024 이조솔루션 All rights reserved.</div>
                </div>
            </div>
        </div>
    );
};

export default Login;
