import React, { useEffect, useState } from 'react';
import DashBoard from './DashBoard';
import FileList from './FileList';
import DoneList from './DoneList';
import MyPage from './MyPage';
import { useLocation, useNavigate } from 'react-router-dom';
import './FilesContent.css';
import InputFileInfo from './InputFileInfo';

const defaultMenu = 'Dashboard';

function FilesContent() {
  const [mainView, setMainView] = useState(defaultMenu);
  const [fileId, setFileId] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const initialMenu = params.get('menu');
    const initialFileId = params.get('fileId');

    if (initialMenu) {
      setMainView(initialMenu);
    } else {
      setMainView(defaultMenu);
    }

    if (initialFileId) {
      setFileId(initialFileId);
    } else {
      setFileId(null);
    }
  }, [location.search]);

  const handleMenuParams = (menu) => {
    const params = new URLSearchParams({ menu });
    navigate(`/?${params.toString()}`);
  };

  const LogOut = () => {
    sessionStorage.setItem('SessionId', null);
    window.location.reload();
  };

  const menus = {
    Dashboard: {
      value: 'Dashboard',
      label: '대시보드',
      component: <DashBoard />,
      iconUrl: '/dashboard/dashboard-icon.svg',
    },
    FileList: {
      value: 'FileList',
      label: '내 사건 목록',
      component: <FileList />,
      iconUrl: '/dashboard/mylist-icon.svg',
    },
    DoneList: {
      value: 'DoneList',
      label: '완료된 사건 목록',
      component: <DoneList />,
      iconUrl: '/dashboard/donelist-icon.svg',
    },
  };

  const subMenus = {
    MyPage: {
      value: 'MyPage',
      label: '마이페이지',
      component: <MyPage />,
      iconUrl: '/dashboard/mypage.svg',
    },
    Help: {
      value: 'Help',
      label: 'Help',
      component: <div />,
      iconUrl: '/dashboard/help.svg',
      onClick: () => {},
    },
    Logout: {
      value: 'Logout',
      label: '로그아웃',
      component: <div />,
      iconUrl: '/dashboard/logout.svg',
      onClick: LogOut,
    },
  };

  return (
    <div className="files-content">
      <aside className="dashboard-aside">
        <div className="dashboard-aside-upper">
          <div className="logo-wrap">
            <img src="drift-logo.svg" alt="drift_logo"></img>
          </div>
          <div className="dashboard-options-wrap">
            {Object.values(menus).map(({ value, label, iconUrl, onClick }) => {
              const isActive = mainView === value;
              const className = `dashboard-option ${isActive && 'active'}`;

              const clickHandler = () => {
                if (onClick) {
                  onClick();
                } else {
                  setMainView(value);
                  handleMenuParams(value);
                }
              };

              return (
                <div key={value} className={className} onClick={clickHandler}>
                  <img alt={value} src={iconUrl} />
                  <div className="dashboard-option-title">{label}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="dashboard-aside-footer">
          {Object.values(subMenus).map(({ value, label, iconUrl, onClick }) => {
            const isActive = mainView === value;
            const className = `footer-option ${isActive && 'active'}`;

            const clickHandler = () => {
              if (onClick) {
                onClick();
              } else {
                setMainView(value);
                handleMenuParams(value);
              }
            };

            return (
              <div key={value} className={className} onClick={clickHandler}>
                <img alt={value} src={iconUrl} className="footer-option-icon" />
                <div className="footer-option-title">{label}</div>
              </div>
            );
          })}
        </div>
      </aside>
      <div className="dashboard-content-main">
        {fileId ? (
          <InputFileInfo fileId={fileId} />
        ) : (
          { ...menus, ...subMenus }[mainView].component || <DashBoard />
        )}
      </div>
    </div>
  );
}

export default FilesContent;
