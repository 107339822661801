import React, { useState } from 'react';

function ToolbarInput() {

    return (
        <div className="toolbar">
            ToolbarInput
        </div>
    );
}

export default ToolbarInput;