import React, { useState, useEffect, useRef } from 'react';
import './TextEditor.css';
import ContentEditable from 'react-contenteditable';
import { usePatentListStore, useIdentifierListStore } from '../store.js';

function TextEditor({ command }) {
    const [textFromContent, setTextFromContent] = useState('');  // 태그 제거된 텍스트 상태
    const [content, setContent] = useState('');  // HTML content 상태
    const { setIdentifiers, setStandardIdentifiers, setClaims, setImages } = useIdentifierListStore();
    const editorRef = useRef(null);

    const handleChange = (evt) => {
        const rawText = evt.target.value;
        const formattedText = applyFormatting(rawText);
        setContent(formattedText);
        updatePlainText(formattedText); // HTML을 평문으로 변환하여 상태 업데이트
    };

    // 텍스트를 분석하고 포맷을 적용하는 함수
    const applyFormatting = (text) => {
        text = text.replace(/&lt;b&gt;(.*?)&lt;\/b&gt;/g, '<b>$1</b>');
        text = text.replace(/&lt;i&gt;(.*?)&lt;\/i&gt;/g, '<i>$1</i>');
        text = text.replace(/&lt;u&gt;(.*?)&lt;\/u&gt;/g, '<u>$1</u>');
        text = text.replace(/&lt;strike&gt;(.*?)&lt;\/strike&gt;/g, '<strike>$1</strike>');
        text = text.replace(/&lt;sup&gt;(.*?)&lt;\/sup&gt;/g, '<sup>$1</sup>');
        text = text.replace(/&lt;sub&gt;(.*?)&lt;\/sub&gt;/g, '<sub>$1</sub>');
        text = text.replace(/\?\?(.*?)\?\?/g, '<div class="identifier claim">$1</div>');
        text = text.replace(/'''(.*?)'''/g, '<div class="index" contenteditable="false">$1</div>');
        text = text.replace(/##(.*?)##/g, '<div class="anchor"><div class="sTag" contenteditable="false">$1</div></div>');
        let citationCounter = 1;

        text = text.replace(/\^\^(.*?)\^\^/g, (match, link) => {
            // 출처 번호 및 툴팁 데이터 속성 추가
            const quoteHTML = `<a class="quote" href="${link}" target="_blank" data-link="${link}" contenteditable="false" data-title="[${citationCounter}]" data-content="${link}">${citationCounter++}</a>`;
            return quoteHTML;
        });
        text = text.replace(/\/\/\/(.*?)\/\/\//g, '<div class="core">$1</div>');
        return text;
    };

    const handlePaste = (evt) => {
        evt.preventDefault();
        const text = evt.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
    };

    const removeClassFromCurrentElement = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
    
        const range = selection.getRangeAt(0);
        const parentElement = range.commonAncestorContainer.nodeType === 1 
            ? range.commonAncestorContainer 
            : range.commonAncestorContainer.parentElement;
        if (parentElement && parentElement.classList.length) {
            console.log(parentElement.classList.length)
            const clonedElement = parentElement.cloneNode(true);
            clonedElement.className = ''; // 클래스 제거
    
            // 기존 요소를 지우고 수정된 클론을 삽입
            parentElement.replaceWith(clonedElement);
        }
    };
    
    const isStart = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return false;
    
        const range = selection.getRangeAt(0);
        const startContainer = range.startContainer;
    
        // 커서가 문서의 시작 부분인지를 확인
        if (startContainer.nodeType === 3) { // 텍스트 노드일 경우
            return range.startOffset === 0; // 커서가 텍스트의 맨 앞에 있는지 확인
        }
    
        // 텍스트 노드가 아닐 경우, 부모 노드에서 확인
        return startContainer === startContainer.ownerDocument.body;
    };
    
    const handleKeyDown = (evt) => {
        if (evt.key === 'Enter') {
            setTimeout(() => {
                if (isStart()) {
                    removeClassFromCurrentElement();
                    console.log("remove")
                }
            }, 10);
        }
    };

    const updatePlainText = (htmlContent) => {
        // HTML을 DOM 파서로 변환
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        // 재귀적으로 하위 요소부터 텍스트를 변환하는 함수
        const parseNode = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                return node.nodeValue;  // 텍스트 노드인 경우 텍스트 그대로 반환
            }
            
            let text = '';
            node.childNodes.forEach((child) => {
                text += parseNode(child);  // 하위 노드를 재귀적으로 호출하여 텍스트 얻기
            });

            if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.nodeName === 'BR') {
                    return '\n';
                }
                if (['B', 'I', 'U', 'STRIKE', 'SUP', 'SUB'].includes(node.nodeName)) {
                    return `<${node.nodeName.toLowerCase()}>${text}</${node.nodeName.toLowerCase()}>`;
                }
                const className = node.getAttribute('class');
                if (className && className.includes('claim')) {
                    return `??${text}??`;
                } else if (className && className.includes('index')) {
                    return `'''${text}'''`;
                } else if (className && className.includes('sTag')) {
                    return `##${text}##`;
                } else if (className && className.includes('quote')) {
                    return `^^${text}^^`;
                } else if (className && className.includes('core')) {
                    return `///${text}///`;
                } 
            }
            return text;
        };

        // 최상위 노드에서 텍스트 변환 시작
        const plainText = parseNode(doc.body);
        // console.log(htmlContent)
        setTextFromContent(plainText);
    };

    useEffect(() => {
        if (command?.cmd === 'insertIdentifier' && editorRef.current) {
            document.execCommand('insertHTML', false, `<br><div class="identifier ${command?.arg}">[${command?.arg}]<div>`);
        } else if (command?.cmd === 'insertPTag' && editorRef.current) {
            document.execCommand('insertHTML', false, '<br><div class="ptag" contenteditable="false">test 문단태그입니다.<div>');
        } else if (command?.cmd === 'insertStandardIdentifier' && editorRef.current) {
            document.execCommand('insertHTML', false, `<br><div class="index ${command?.arg}">[${command?.arg}]<div>`);
        } else if (command && editorRef.current) {
            document.execCommand(command.cmd, false, command.arg);
        }
    }, [command, editorRef]);

    useEffect(() => {
        const extractIdentifiers = () => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');

            const identifierElements = doc.querySelectorAll('.identifier');
            const identifierTexts = Array.from(identifierElements).map(el => el.innerText);

            const standardIdentifierElements = doc.querySelectorAll('.index');
            const standardIdentifierTexts = Array.from(standardIdentifierElements).map(el => el.innerText);

            const claimElements = doc.querySelectorAll('.claim');
            const claimTexts = Array.from(claimElements).map(el => el.innerText);

            const imageElements = doc.querySelectorAll('.image');
            const imageTexts = Array.from(imageElements).map(el => el.innerText);

            setIdentifiers(identifierTexts);
            setStandardIdentifiers(standardIdentifierTexts);
            setClaims(claimTexts);
            setImages(imageTexts);
        };

        extractIdentifiers();
    }, [content]);

    return (
        <div className="editor-container">
            <ContentEditable
                innerRef={editorRef}
                className="text-container"
                tagName="pre"
                html={content}
                onChange={handleChange}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
}

export default TextEditor;
