import React, { useState } from 'react';

function ToolbarView() {

    return (
        <div className="toolbar">
            ToolbarView
        </div>
    );
}

export default ToolbarView;