import { createContext, useState } from 'react';
import Modals from './Modals';

export const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [modals, setModals] = useState(new Map());

  const openModal = (id, component, shouldCloseModal) => {
    setModals(() => {
      const newModals = new Map(modals);
      newModals.set(id, { id, component, shouldCloseModal });
      return newModals;
    });
  };

  const closeModal = (id) => {
    setModals(() => {
      const newModals = new Map(modals);
      newModals.delete(id);
      return newModals;
    });
  };

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal }}>
      <Modals modals={modals} closeModal={closeModal} />
      {children}
    </ModalContext.Provider>
  );
}
