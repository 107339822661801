import './Modals.css';
import { createPortal } from 'react-dom';

const portalElement = document.getElementById('modal-root');

function Modals({ modals, closeModal }) {
  if (!modals.size) return null;

  return createPortal(
    Array.from(modals.entries()).map(
      ([id, { component, shouldCloseModal }]) => (
        <div key={id}>
          <div
            className="modal-overlay"
            onClick={() => {
              if (!shouldCloseModal || shouldCloseModal()) {
                closeModal(id);
              }
            }}
          />
          <div className="modal">{component}</div>
        </div>
      )
    ),
    portalElement
  );
}

export default Modals;
