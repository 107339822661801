import React, { useState, useEffect, useMemo } from 'react';
import './FilesContent.css';
import { useNavigate } from 'react-router-dom';
import { usePatentListStore } from '../store.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Airtable from 'airtable';
import File from './File.js';

function DoneList() {
  const navigate = useNavigate();
  const { patentList } = usePatentListStore();
  const [fileList, setFileList] = useState([]);
  const userID = sessionStorage.getItem('SessionId');

  const [isLoading, setIsLoading] = useState(true);

  // Airtable 데이터 가져오기
  useEffect(() => {
    const base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base('appZiodrIIUYXLYpC');

    base('input data')
      .select({
        maxRecords: 10,
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          const recordsList = records.map((record) => record.fields);
          setFileList((prevList) => [...prevList, ...recordsList]); // 리스트에 추가
          fetchNextPage();
          setIsLoading(false); // 로딩 상태로 전환
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
  const itemsPerPage = 12; // 한 페이지에 표시할 아이템 수

  const paginatedList = useMemo(() => {
    const filteredFiles = fileList?.filter(
      (file) => file['회원정보'] === userID
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredFiles?.slice(startIndex, endIndex);
  }, [fileList, userID, currentPage]);

  const totalPages = useMemo(() => {
    const totalItems =
      fileList?.filter((file) => file['회원정보'] === userID)?.length || 0;
    return Math.ceil(totalItems / itemsPerPage);
  }, [fileList, userID]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleCreateNewFile = () => {
    navigate(`/new`);
  };

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1000,
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <div className="wait">Loading</div>
        </Box>
      )}
      <div className="dashboard-header-wrap">
        <div className="dashboard-header-right">
          <div className="dashboard-user-name">
            {sessionStorage.getItem('SessionId')}
          </div>
          <div className="greetings">님 안녕하세요!</div>
          <img className="alarm-icon" src="/dashboard/alarm-icon.svg" />
        </div>
      </div>
      <div className="dashboard-main-wrap">
        <div className="patent-list-title-wrap">
          <div className="patent-list-title">완료된 사건 목록</div>
          <div className="delete-notification">
            완료된 사건은 30일간 보관 후 삭제됩니다.
          </div>
        </div>
        <div className="patent-list-wrap">
          <div className="patent-list-header">
            <div className="patent-id">사건번호</div>
            <div className="patent-title">사건명</div>
            <div className="patent-client">클라이언트</div>
            <div className="patent-start">수임일</div>
            <div className="patent-due">초안 송부 기한</div>
            <div className="patent-status">상태</div>
            <div className="patent-action">
              <img src="/dashboard/sort.svg"></img>
            </div>
          </div>
          {paginatedList.map((file) => {
            const patentID = file['사건번호'];
            const patentTitle = file['제목'];
            const patentClient = file['클라이언트'];
            const patentDue = file['마감기한'];
            const patentDone = file['output data'];

            return (
              <File
                key={patentID}
                id={patentID}
                title={patentTitle}
                client={patentClient}
                due={patentDue}
                done={patentDone}
              />
            );
          })}
        </div>
        <div className="pagination-wrap">
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            <img src="/dashboard/go-first-page.svg"></img>
          </div>
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src="/dashboard/prev-page.svg"></img>
          </div>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`pagination-btn ${
                currentPage === index + 1 ? 'active' : ''
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img src="/dashboard/next-page.svg"></img>
          </div>
          <div
            className="pagination-btn"
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            <img src="/dashboard/go-last-page.svg"></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoneList;
