import { useState } from 'react';
import Tab from '../components/Tab';
import './InputFileInfo.css';
import { useNavigate } from 'react-router-dom';

const MENUS = [
  { label: '청구항', value: 'CLAIM' },
  { label: '발명내용', value: 'INVENTION' },
  { label: '도면', value: 'DRAWING' },
];

const LABELS = MENUS.map((menu) => menu.label);

function InputFileInfo({ fileId }) {
  const navigate = useNavigate();

  const [claim, setClaim] = useState('');
  const [inventionField, setInventionField] = useState(null);
  const [inventionContent, setInventionContent] = useState('');
  const [files, setFiles] = useState([]);
  const [fileDescription, setFileDescription] = useState([]);
  const [drawingCompositions, setDrawingCompositions] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const previews = files.map((file) => URL.createObjectURL(file));
    setFiles(previews);
  };

  const handleSave = () => {};

  const handleNext = () => {
    if (tabIndex < MENUS.length - 1) {
      setTabIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (tabIndex > 0) {
      setTabIndex((prev) => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = () => {};

  return (
    <div className="input-file-info-container">
      <div className="input-file-info-header">
        <div className="input-file-info-header-top">
          <div className="input-file-info-title">{`${fileId}`}</div>
          <div className="input-file-info-btn-container">
            <button className="input-file-info-btn">임시 저장</button>
            <button className="input-file-info-btn submit">생성하기</button>
          </div>
        </div>
        <div>
          <Tab labels={LABELS} currentIndex={tabIndex} setIndex={setTabIndex} />
        </div>
      </div>
      <div className="input-file-info-contents">
        {(function () {
          // TODO: 컴포넌트로 분리
          return [
            <div className="content-wrap">
              <div className="content-main-left">
                <div className="content-title-wrap">
                  <div className="content-title">청구항</div>
                  <div className="required-option-label">(필수)</div>
                </div>
              </div>
              <textarea
                className="patent-claim"
                placeholder="청구항을 입력해주세요."
                value={claim}
                onChange={(e) => setClaim(e.target.value)}
              />
            </div>,
            <div className="content-wrap">
              <div className="content-main-left">
                <div className="content-title-wrap">
                  <div className="content-title">발명 내용</div>
                </div>
              </div>
              <textarea
                className="patent-claim"
                placeholder="청구항을 입력해주세요."
                value={claim}
                onChange={(e) => setClaim(e.target.value)}
              />
            </div>,
            <div className="content-wrap">
              <div className="content-main-left">
                <div className="content-title-wrap">
                  <div className="content-title">도면</div>
                </div>
              </div>
              <textarea
                className="patent-claim"
                placeholder="청구항을 입력해주세요."
                value={claim}
                onChange={(e) => setClaim(e.target.value)}
              />
            </div>,
          ][tabIndex];
        })()}

        {/* 
          {files.length > 0 && (
            <div className="file-previews">
              {files.map((preview, index) => (
                <div key={index} className="file-preview">
                  <img src={preview} alt={`도면 미리보기 ${index + 1}`} />
                </div>
              ))}
            </div>
          )}
        </div> */}
        <div
          className="input-file-info-btn-container"
          style={{ flexDirection: 'row-reverse', marginTop: '28px' }}
        >
          {tabIndex < MENUS.length - 1 && (
            <button className="input-file-info-btn" onClick={handleNext}>
              다음
            </button>
          )}
          <button className="input-file-info-btn back" onClick={handlePrev}>
            이전
          </button>
        </div>
      </div>
    </div>
  );
}

export default InputFileInfo;
