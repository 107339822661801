import { useLocation, useNavigate } from 'react-router-dom';
import './File';

function File({ id, title, client, start, due, done }) {
  const navigate = useNavigate();
  const location = useLocation();

  const editPatent = (id) => {
    const params = new URLSearchParams(location.search);
    const currentMenu = params.get('menu');

    const newParams = new URLSearchParams({ menu: currentMenu, fileId: id });
    navigate(`/?${newParams.toString()}`);
  };

  const editContent = (id) => {
    navigate(`/contentEditor?id=${id}`);
  };

  const handleClick = () => {
    if (done) {
      editContent(id);
    } else {
      editPatent(id);
    }
  };

  return (
    <div className="patent-info">
      <div className="patent-id">{id}</div>
      <div className="patent-title" onClick={handleClick}>
        {title}
      </div>
      <div className="patent-client">{client}</div>
      <div className="patent-start">{start}</div>
      <div className="patent-due">{due}</div>
      <div className="patent-status">
        {done ? (
          <div className="patent-status-info done-process">편집 가능</div>
        ) : (
          <div className="patent-status-info on-processing">처리중</div>
        )}
      </div>
      <div className="patent-action">
        <img alt="more" src="/dashboard/more.svg"></img>
      </div>
    </div>
  );
}

export default File;
